import { Icon } from "@iconify/react";

// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import e from "cors";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppEstimationPercentage({ data }) {
  const estimation = { respected: 0, total: 0 };
  // console.log(data);
  for (const card of data.cards ?? []) {
    if (!["1305652","1328178","1330316","1266301"].includes(card.testId)) {
      if (card?.difficulty > 4 || !card?.difficulty) {
        continue;
      }
      if (card?.tseTime <= card?.difficulty * 3) {
        console.log('estimation', card.tseTime);
        estimation.respected++;
      }
      estimation.total++;
    }
  }

  // console.log(estimation);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon="bi:clock-fill" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h5">
        TSE Time Respected Estimation
        <p>
          {((estimation.respected / estimation.total) * 100 || 0).toFixed(0)}%
        </p>
      </Typography>
    </RootStyle>
  );
}
