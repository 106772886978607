/* eslint-disable no-loop-func */
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

// material
import {
  Box,
  Grid,
  Container,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { display } from "@mui/system";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppEstimationTime,
  AppTrelloQualityScore,
  AppActivityRate,
  AppNewsUpdate,
  AppRespectedEstimation,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  AppNPS,
  AppCDC,
  AppEstimationPercentage,
} from "../components/_dashboard/app";

import boardMembers from "./../config/trelloBoardMembers.json";
import brogglEntries from "./../config/brogglJsonExampleQ3.json";
import tseMembers from "./../config/tseMembers.json";
import baMembers from "./../config/baMembers.json";
import managerList from "./../config/managerList.json";
import tseExpertList from "./../config/tseExpertList.json";

import exportJanvier2022 from "./../config/exportJanvier2022.json";
import exportTimeById from "./../config/exportTimeById.json";
import setConnectedData from "../layouts/SetConnectedData";

import locationURLs from "../config/location.json";

if (
  localStorage.getItem("user_token") &&
  !localStorage.getItem("user_trello_id") &&
  !localStorage.getItem("user_trello_photo") &&
  !localStorage.getItem("user_email")
) {
  setConnectedData();
}

const monthByQuarter = {
  Q3_2022: '["01/2022","02/2022","03/2022"]',
  Q4_2022: '["04/2022","05/2022","06/2022"]',
  Q1_2023: '["07/2022","08/2022","09/2022"]',
  Q2_2023: '["10/2022","11/2022","12/2022"]',
  Q3_2023: '["01/2023","02/2023","03/2023"]',
  Q4_2023: '["04/2023","05/2023","06/2023"]',
  Q1_2024: '["07/2023","08/2023","09/2023"]',
  Q2_2024: '["10/2023","11/2023","12/2023"]',
  Q3_2024: '["01/2024","02/2024","03/2024"]',
  Q4_2024: '["04/2024","05/2024","06/2024"]',
  Q1_2025: '["07/2024","08/2024","09/2024"]',
  Q2_2025: '["10/2024","11/2024","12/2024"]',
  Q3_2025: '["01/2025","02/2025","03/2025"]',
};

//here to change the quarter at loading
var months =
  monthByQuarter[location.search.match(/(Q[1-4]_\d{4})/g)?.[0] || "Q3_2025"];

//var fullName = "";
var nullDataTemplate = [
  {
    id: "000",
    fullName: "No Data",
    username: "nodata",
    cards: [
      {
        testId: "000000",
        startDate: "2021-10-22T07:57:30.711Z",
        difficulty: 2,
        timeDuration: 6.5,
        estimationRespected: false,
      },
    ],
    averageCdc: null,
    cdcTally: 0,
    averageDifficulty: 0,
    difficultyTally: 0,
    difficultyScore: 0,
    respectedEstimation: 0,
    respectedEstimationTally: 0,
    trelloWorkRate: 0,
    goalTrelloQualityScore: "0",
    goalRespectedEstimation: "0",
    goalActivityRate: "0",
    weightedWorkRate: 0,
    tqs: 0,
    achievement: 0,
  },
];

var tsesData = [];
var tseMembersTeam;

function getTSEJson(quarterData, tseMembersTeam) {
  for (var key in quarterData) {
    //console.log(quarterData[key]);
    //console.log(quarterData[key].fullName);

    var memberData = quarterData[key];
    var memberId = memberData.id;

    tseMembersTeam.map((tse, index) => {
      if (tse.id === memberId) {
        //initiate new entries
        // console.log(`memberData`, memberData);
        memberData.cdcTally = 0;
        memberData.averageDifficulty = 0;
        memberData.difficultyTally = 0;
        memberData.difficultyScore = 0;
        memberData.respectedEstimation = 0;
        memberData.respectedEstimationTally = 0;

        //add specific data into the new array
        memberData.fullName = tse.brogglName;
        memberData.trelloWorkRate = tse.trelloWorkRate;
        memberData.goalTrelloQualityScore = tse.trelloQualityScore;
        memberData.goalRespectedEstimation = tse.timeDevEstimation;
        memberData.goalActivityRate = tse.activityRate;
        memberData.cdcTarget = tse.cdcTarget;

        //CDC regulation
        memberData.cdcList = [];

        //add datas for each card of the tse
        if (memberData?.cards) {
          for (const card of memberData.cards) {
            if (!card) {
              continue;
            }

            if (card?.cdc) {
              memberData.averageCdc += card.cdc;
              memberData.cdcList.push(card.cdc);
              memberData.cdcTally++;
            }
            if (card?.difficulty) {
              memberData.averageDifficulty += card.difficulty;
              memberData.difficultyTally++;
              memberData.difficultyScore += card.difficulty;
            }

            if (!memberData?.feasibilityCards) {
              if (card?.estimationRespected) {
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else {
                memberData.respectedEstimationTally++;
              }
            }
          }

          // old CDC
          memberData.averageCdc =
            memberData.averageCdc / memberData.cdcTally / (1000 * 60 * 60 * 24);
          // let memberCDCTotal = 0;
          // for (
          //   let i = +(memberData.cdcList.length * 0.05).toFixed(0);
          //   i <
          //   memberData.cdcList.length -
          //     +(memberData.cdcList.length * 0.05).toFixed(0);
          //   i++
          // ) {
          //   memberCDCTotal += memberData.cdcList[i];
          // }

          // memberData.averageCdc =
          //   memberCDCTotal / memberData.cdcTally -
          //   +(memberData.cdcList.length * 0.1).toFixed(0);
          memberData.averageDifficulty =
            Math.round(
              (memberData.averageDifficulty / memberData.difficultyTally) * 100
            ) / 100;

          memberData.respectedEstimation =
            (memberData.respectedEstimation /
              memberData.respectedEstimationTally) *
            100;
        }
        if (memberData?.feasibilityCards) {
          // console.log('your data', memberData);
          memberData.respectedEstimation = 0;
          memberData.respectedEstimationTally = 0;
          for (const card of memberData.feasibilityCards) {
            if (card?.estimationRespected) {
              memberData.respectedEstimation++;
              memberData.respectedEstimationTally++;
            } else {
              memberData.respectedEstimationTally++;
            }
          }
          // console.log(
          //   memberData.respectedEstimation,
          //   memberData.respectedEstimationTally,
          // );

          memberData.respectedEstimation =
            (memberData.respectedEstimation /
              memberData.respectedEstimationTally) *
            100;
        }

        //add new complete entries for this TSE in the final array
        tsesData.push(memberData);
        // console.log(memberData);
        if (tse.trelloWorkRate !== 0) {
          tsesData.workRateTally = tsesData.workRateTally
            ? tsesData.workRateTally + parseFloat(memberData.trelloWorkRate)
            : memberData.trelloWorkRate;
          tsesData.cdcTally = tsesData.cdcTally
            ? tsesData.cdcTally + memberData.averageCdc
            : memberData.averageCdc;
          // tsesData.cdcAverage = tsesData.cdcAverage
          //   ? tsesData.cdcAverage.concat(memberData.cdcList)
          //   : memberData.cdcList;
        }
        if (tse.trelloWorkRate !== 0)
          tsesData.difficultyTally = tsesData.difficultyTally
            ? tsesData.difficultyTally + memberData.difficultyTally
            : memberData.difficultyTally;
        if (tse.trelloWorkRate !== 0)
          tsesData.difficultyScoreTally = tsesData.difficultyScoreTally
            ? tsesData.difficultyScoreTally + memberData.difficultyScore
            : memberData.difficultyScore;
      }
    });
  }
}

function getTimeById(idTest) {
  return exportTimeById[idTest];
}

function addTimePassedOnCard(card) {
  var timePassed = getTimeById(card.testId);
  return timePassed;
}

function median(array) {
  array.sort(function (a, b) {
    return a - b;
  });
  const half = Math.floor(array.length / 2);
  if (array.length % 2) return array[half];
  return (array[half - 1] + array[half]) / 2.0;
}

async function calculateTSEQuarterResults(tsesData) {
  let workRateTally = 0;
  var difficultyScoreTally = tsesData.difficultyScoreTally;
  let cdcAverage = tsesData.cdcAverage;

  // median of the cdc average
  // tsesData.averageCdc = median(cdcAverage);

  for (const tse of tsesData) {
    tse.offDays = await getOffDays(tse.fullName);
    workRateTally += tse.offDays * tse.trelloWorkRate;
  }
  tsesData.workRateTally = workRateTally;

  tsesData.map((tse) => {
    if (tse.trelloWorkRate === 0) {
      return;
    }
    tse.weightedWorkRate = (tse.trelloWorkRate * tse.offDays) / workRateTally;
    tse.tqs = tse.weightedWorkRate * difficultyScoreTally * (100 / 105);
    tse.achievement = (tse.difficultyScore / tse.tqs) * 100;

    tsesData.tqsTally = tsesData.tqsTally
      ? tsesData.tqsTally + tse.tqs
      : tse.tqs;

    //add activity rate
    //if(sessionStorage.getItem('dashboardName') != "No Data") {
    //  TSEactivityRate();
    //}
  });

  return tsesData;
}

const getQuarterData = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ""
      }/getMonthlyData?months=${months}`
    );
    var quarterData = await response.json();
    resolve(quarterData);
  });
};

const getActivityData = async (fullName) => {
  //fullName = sessionStorage.getItem('dashboardName');
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ""
      }/getActivityRate?months=${months}&name=${fullName}`
    );
    //const response = await fetch(
    //  `${"http://localhost:3001"}/getMonthlyData?months=["01/2022", "02/2022", "03/2022"]`
    //);
    var activityData = await response.json();
    resolve(activityData);
  });
};

async function getOffDays(fullName) {
  return await fetch(
    `${
      locationURLs[process.env.REACT_APP_URL] || ""
    }/getActivityRate?months=${months}&name=${fullName}`
  ).then((response) =>
    response.json().then((json) => {
      let offDays = 0;
      let totalHours = 1;
      for (const month in json) {
        offDays += json[month].offDays;
        totalHours += json[month].totalHours;
      }
      return (totalHours - offDays * 8) / totalHours;
    })
  );
}

function calculateActivityRate(activityData) {
  let totalHours = 0;
  let activityHours = 0;
  for (const month in activityData) {
    activityHours += activityData[month].activity;
    totalHours +=
      activityData[month].totalHours - activityData[month].offDays * 8;
  }

  return (activityHours / totalHours) * 100;
}

const TSEactivityRate = async (allTseData) => {
  // console.log(0);
  for (var tse of allTseData) {
    var activityData = await getActivityData(tse.fullName);
    var activityRate = calculateActivityRate(activityData);
    tse.activityRate = activityRate;
    // console.log(tse);
  }
  return allTseData;
};

const key = "e478e9b2b63fff839170a52eb5ad9c22";
const secret =
  "41e5700bde382ea031a14816635e9629302169afa834d2d878a2f7ce205e6bc8";

const trelloRequestOptions = {
  method: "GET",
  headers: {
    Accept: "application/json",
  },
};

const getAllTseData = function async(tsesData) {
  return tsesData;
};

const Dashboard = () => {
  //const [tsesData, setTsesData] = useState([]);
  const [allTseData, setAllTseData] = useState(nullDataTemplate);
  const [cardsWithoutDueDate, setCardsWithoutDueDate] = useState([]);
  const [tqsArray, setTqsArray] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [cdcGlogal, setCdcGlobal] = useState([]);

  const [tsesGlobalData, setTsesGlobalData] = useState([]);
  const [globalOKRData, setGlobalOKRData] = useState({ nps: 0 });
  const [tsesGlobalCards, setTsesGlobalCards] = useState({
    cards: [],
    cdcTarget: "8",
  });

  const [view, setView] = useState([]);

  const [quarterMonth, setQuarterMonth] = useState(months);

  useEffect(() => {
    fetch(`${locationURLs[process.env.REACT_APP_URL] || ""}/getGlobalOKR`, {
      method: "post",
      body: JSON.stringify({ name: "nps" }),
      headers: { "Content-type": "application/json" },
    }).then((response) =>
      response.json().then((json) => setGlobalOKRData({ nps: json.data }))
    );
  }, []);

  const handleQuarterChange = (event, newQuarter) => {
    months = newQuarter;
    setQuarterMonth(newQuarter);
    //console.log(months);
  };

  tseMembersTeam = tseMembers.filter((tse) => tse.active === true);
  // console.log('dashboard');
  //console.log(tseMembers)
  // console.log(tseMembersTeam);

  useEffect(() => {
    const loadTable = async () => {
      tsesData = [];
      var quarterData = await getQuarterData();
      //console.log(quarterData);
      var tseJson = getTSEJson(quarterData, tseMembersTeam);
      //console.log(tseJson);
      tsesData = await calculateTSEQuarterResults(tsesData);
      // console.log(tsesData);
      var allTseData = await getAllTseData(tsesData);
      //console.log(allTseData);

      //specific case of none data for the quarter
      if (allTseData.length == 0) {
        allTseData = nullDataTemplate;
      }

      // if (sessionStorage.getItem('name') != null) {
      allTseData = await TSEactivityRate(allTseData);
      // }

      //set data to display
      // console.log('alltseData', allTseData);
      setAllTseData(allTseData);

      const globalCards = [];
      for (const tse of tsesData) {
        if (tse.id) {
          if (!tse?.cards) continue;
          tse.cards.forEach((card) => {
            globalCards.push(card);
          });
        }
      }
      setTsesGlobalCards({
        cards: globalCards,
        cdcTarget: 8,
      });

      //set specific data on dashboard
      setTsesGlobalData(tsesData);

      // console.log(tsesData);

      console.log("your raw data", allTseData);
      console.log("current global raw data", {
        totalDifficultyScore: tsesData.difficultyScoreTally,
        totalWorkRate: tsesData.workRateTally,
        cdc: tsesData.averageCdc,
      });
    };

    loadTable();
  }, [view, quarterMonth]);

  return (
    <Page title="Okr & Stats">
      <Container maxWidth="xl" className="team_list">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h5">OKRs Globaux</Typography>
        </Box>
        <Grid container spacing={3}>
          <Container maxWidth="xl">
            <Box component="div" sx={{ display: "flex", mb: 5 }}>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppCDC data={tsesGlobalCards} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppRespectedEstimation data={tsesGlobalCards} />
              </Grid>
            </Box>
          </Container>
        </Grid>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">TEAM OKRs</Typography>
        </Box>
        <Grid container spacing={3}>
          {allTseData.map((tse, index) => {
            return (
              <Container key={index} maxWidth="xl">
                <Box sx={{ mb: 0.5, mx: 2 }}>
                  <Typography variant="h5">{tse.fullName}</Typography>
                </Box>
                <Box component="div" sx={{ display: "flex", mb: 5 }}>
                  <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                    <AppActivityRate data={tse} />
                  </Grid>
                  {!managerList.includes(tse.id) &&
                    !tseExpertList.includes(tse.id) && (
                      <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                        <AppTrelloQualityScore data={tse} />
                      </Grid>
                    )}{" "}
                  <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                    <AppCDC data={tse} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                    <AppRespectedEstimation data={tse} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                    <AppEstimationPercentage data={tse} />
                  </Grid>
                </Box>
              </Container>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
