import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Page from "../components/Page";
import {
  AppTrelloQualityScore,
  AppActivityRate,
  AppRespectedEstimation,
  AppCDC,
  AppNPS,
  AppEstimationPercentage,
} from "../components/_dashboard/app";

import tseMembers from "./../config/tseMembers.json";
import tseExpertList from "./../config/tseExpertList.json";
import exportTimeById from "./../config/exportTimeById.json";
import { forEach } from "lodash";
import setConnectedData from "../services/SetConnectedData";
import isManager from "../services/isManager";

import locationURLs from "../config/location.json";
import { connection } from "mongoose";

let tsesData = [];

if (
  !localStorage.getItem("user_trello_id") &&
  !localStorage.getItem("user_trello_photo") &&
  !localStorage.getItem("user_email")
) {
  setConnectedData();
}

const monthByQuarter = {
  Q3_2022: '["01/2022","02/2022","03/2022"]',
  Q4_2022: '["04/2022","05/2022","06/2022"]',
  Q1_2023: '["07/2022","08/2022","09/2022"]',
  Q2_2023: '["10/2022","11/2022","12/2022"]',
  Q3_2023: '["01/2023","02/2023","03/2023"]',
  Q4_2023: '["04/2023","05/2023","06/2023"]',
  Q1_2024: '["07/2023","08/2023","09/2023"]',
  Q2_2024: '["10/2023","11/2023","12/2023"]',
  Q3_2024: '["01/2024","02/2024","03/2024"]',
  Q4_2024: '["04/2024","05/2024","06/2024"]',
  Q1_2025: '["07/2024","08/2024","09/2024"]',
  Q2_2025: '["10/2024","11/2024","12/2024"]',
  Q3_2025: '["01/2025","02/2025","03/2025"]',
};

//here to change the quarter at loading
var months = monthByQuarter["Q3_2025"];

let fullName = "";
let nullDataTemplate = [
  {
    id: "000",
    fullName: "No Data",
    username: "nodata",
    cards: [
      {
        testId: "000000",
        startDate: "2021-10-22T07:57:30.711Z",
        difficulty: 2,
        timeDuration: 6.5,
        estimationRespected: false,
      },
    ],
    averageCdc: null,
    cdcTally: 0,
    averageDifficulty: 0,
    difficultyTally: 0,
    difficultyScore: 0,
    respectedEstimation: 0,
    respectedEstimationTally: 0,
    trelloWorkRate: 0,
    goalTrelloQualityScore: "0",
    goalRespectedEstimation: "0",
    goalActivityRate: "0",
    weightedWorkRate: 0,
    tqs: 0,
    achievement: 0,
  },
];

// console.log(tseMembers);

function getTSEJson(quarterData, tseMembers) {
  for (let key in quarterData) {
    let memberData = quarterData[key];
    let memberId = memberData.id;

    tseMembers.map((tse, index) => {
      if (!tse.active) return;
      if (tse.id === memberId) {
        // Initiate new entries
        // memberData.averageCdc = 0;
        memberData.cdcTally = 0;
        memberData.averageDifficulty = 0;
        memberData.difficultyTally = 0;
        memberData.difficultyScore = 0;
        memberData.respectedEstimation = 0;
        memberData.respectedEstimationTally = 0;

        // Add specific data into the new array
        memberData.fullName = tse.brogglName;
        memberData.trelloWorkRate = tse.trelloWorkRate;
        memberData.goalTrelloQualityScore = tse.trelloQualityScore;
        memberData.goalRespectedEstimation = tse.timeDevEstimation;
        memberData.goalActivityRate = tse.activityRate;
        memberData.cdcTarget = tse.cdcTarget;

        //CDC regulation
        memberData.cdcList = [];

        // Add datas on each card of the tse
        if (memberData?.cards) {
          for (const card of memberData.cards) {
            if (!card) {
              continue;
            }

            card.timeDuration = addTimePassedOnCard(card);

            if (card?.cdc) {
              memberData.averageCdc += card.cdc;
              memberData.cdcList.push(card.cdc);
              memberData.cdcTally++;
            }
            if (card?.difficulty) {
              memberData.averageDifficulty += card.difficulty;
              memberData.difficultyTally++;
              memberData.difficultyScore += card.difficulty;
            }

            if (!memberData?.feasibilityCards) {
              if (card?.estimationRespected) {
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else {
                memberData.respectedEstimationTally++;
              }
            }
          }

          memberData.respectedEstimation =
            (memberData.respectedEstimation /
              memberData.respectedEstimationTally) *
            100;

          memberData.averageCdc =
            memberData.averageCdc / memberData.cdcTally / (1000 * 60 * 60 * 24);
          // let memberCDCTotal = 0;
          // for (
          //   let i = +(memberData.cdcList.length * 0.05).toFixed(0);
          //   i <
          //   memberData.cdcList.length -
          //     +(memberData.cdcList.length * 0.05).toFixed(0);
          //   i++
          // ) {
          //   memberCDCTotal += memberData.cdcList[i];
          // }
          // console.log(
          //   'ancien cdc',
          //   memberData.averageCdc / memberData.cdcTally,
          // );
          // console.log('total CDC', memberCDCTotal);
          // memberData.averageCdc =
          //   memberCDCTotal / memberData.cdcTally -
          //   +(memberData.cdcList.length * 0.1).toFixed(0);
          memberData.averageDifficulty =
            Math.round(
              (memberData.averageDifficulty / memberData.difficultyTally) * 100
            ) / 100;
        }
        if (memberData?.feasibilityCards) {
          memberData.respectedEstimation = 0;
          memberData.respectedEstimationTally = 0;
          for (const card of memberData.feasibilityCards) {
            if (card?.estimationRespected) {
              memberData.respectedEstimation++;
              memberData.respectedEstimationTally++;
            } else {
              memberData.respectedEstimationTally++;
            }
          }

          memberData.respectedEstimation =
            (memberData.respectedEstimation /
              memberData.respectedEstimationTally) *
            100;
        }

        // Add new complete entries for this TSE in the final array
        tsesData.push(memberData);
        //console.log('memberData', memberData);
        if (tse.trelloWorkRate !== 0)
          tsesData.workRateTally = tsesData.workRateTally
            ? tsesData.workRateTally + parseFloat(memberData.trelloWorkRate)
            : memberData.trelloWorkRate;
        tsesData.cdcTally = tsesData.cdcTally
          ? tsesData.cdcTally + memberData.averageCdc
          : memberData.averageCdc;
        // tsesData.cdcAverage = tsesData.cdcAverage
        //   ? tsesData.cdcAverage.concat(memberData.cdcList)
        //   : memberData.cdcList;
        if (tse.trelloWorkRate !== 0)
          tsesData.difficultyTally = tsesData.difficultyTally
            ? tsesData.difficultyTally + memberData.difficultyTally
            : memberData.difficultyTally;
        if (tse.trelloWorkRate !== 0)
          tsesData.difficultyScoreTally = tsesData.difficultyScoreTally
            ? tsesData.difficultyScoreTally + memberData.difficultyScore
            : memberData.difficultyScore;
      }
    });
  }
}

function getTimeById(idTest) {
  return exportTimeById[idTest];
}

function addTimePassedOnCard(card) {
  let timePassed = getTimeById(card.testId);
  return timePassed;
}

function median(array) {
  array.sort((a, b) => a - b);
  const half = Math.floor(array.length / 2);
  if (array.length % 2) return array[half];
  return (array[half - 1] + array[half]) / 2.0;
}

async function calculateTSEQuarterResults(tsesData) {
  let workRateTally = 0;
  let difficultyScoreTally = tsesData.difficultyScoreTally;
  let cdcAverage = tsesData.cdcAverage;

  // tsesData.averageCdc = median(cdcAverage);

  for (const tse of tsesData) {
    tse.offDays = await getOffDays(tse.fullName);
    workRateTally += tse.offDays * tse.trelloWorkRate;
  }
  tsesData.workRateTally = workRateTally;

  tsesData.map((tse) => {
    tse.weightedWorkRate = (tse.trelloWorkRate * tse.offDays) / workRateTally;
    tse.tqs = tse.weightedWorkRate * difficultyScoreTally * (100 / 105);
    tse.achievement = (tse.difficultyScore / tse.tqs) * 100;

    tsesData.tqsTally = tsesData.tqsTally
      ? tsesData.tqsTally + tse.tqs
      : tse.tqs;
  });

  return tsesData;
}

async function getOffDays(fullName) {
  return await fetch(
    `${
      locationURLs[process.env.REACT_APP_URL] || ""
    }/getActivityRate?months=${months}&name=${fullName}`
  ).then((response) =>
    response.json().then((json) => {
      let offDays = 0;
      let totalHours = 1;
      for (const month in json) {
        offDays += json[month].offDays;
        totalHours += json[month].totalHours;
      }
      return (totalHours - offDays * 8) / totalHours;
    })
  );
}

const getQuarterData = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ""
      }/getMonthlyData?months=${months}`
    );
    // const response = await fetch(
    //   `${
    //     locationURLs[process.env.REACT_APP_URL]
    //   }/getMonthlyData?months=["01/2022", "02/2022", "03/2022"]`,
    // );
    let quarterData = await response.json();
    resolve(quarterData);
  });
};

const getActivityData = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ""
      }/getActivityRate?months=${months}&name=${fullName}`
    );
    //const response = await fetch(
    //  `${"http://localhost:3001"}/getMonthlyData?months=["01/2022", "02/2022", "03/2022"]`
    //);
    let activityData = await response.json();

    resolve(activityData);
  });
};

function calculateTQSwithDayOff(activityData, tse) {
  var compiledOffDay = 0;
  var compiledTotalHours = 0;
  var newTqs = tse.difficultyScore;

  // console.log(tse.fullName);

  for (var m in activityData) {
    var monthActivity = activityData[m];
    var offDays = monthActivity.offDays;
    var totalHours = monthActivity.totalHours;

    // console.log('offDays ' + offDays);
    if (offDays != 0) {
      compiledOffDay = compiledOffDay + offDays;
      compiledTotalHours = compiledTotalHours + totalHours;
    }
  }

  if (compiledOffDay != 0) {
    // console.log(tse.difficultyScore);
    newTqs =
      (tse.difficultyScore * compiledTotalHours) /
      (compiledTotalHours - 8 * compiledOffDay);
    // console.log('new tqs ' + newTqs);
  }
  // console.log('________');
  return newTqs;
}

function calculateActivityRate(activityData) {
  let totalHours = 0;
  let activityHours = 0;
  for (const month in activityData) {
    activityHours += activityData[month].activity;
    totalHours +=
      activityData[month].totalHours - activityData[month].offDays * 8;
  }

  return (activityHours / totalHours) * 100;
}

let trelloApiKey = "ae073b471b1634609d1c64de12390012";
// let trelloApiToken =
//   "ac471bdba69fd8432a13a0a01f972dc4b6a10af5f8b69005c953a46f6814b7a6";

const trelloRequestOptions = {
  method: "GET",
  headers: {
    Accept: "application/json",
  },
};

const getConnectedTse = async function async(tsesData) {
  let connectedTseId = "";
  const response = await fetch(
    `https://api.trello.com/1/members/me?key=${trelloApiKey}&token=${localStorage.getItem(
      "user_token"
    )}`,
    trelloRequestOptions
  );
  const connectedUserData = await response.json();
  connectedTseId = connectedUserData.id;
  // console.log(tsesData);
  let finalData = tsesData.filter((tse) => tse.id === connectedTseId);
  // console.log(finalData);
  return finalData;
};

const hasTokenInLocalStorage = () => {
  return localStorage.getItem("user_token") !== null;
};

const hasTokenInUrl = () => {
  const token = window.location?.hash.replace("#token=", "");
  if (token !== "") {
    localStorage.setItem("user_token", token);
    return true;
  }
  return false;
};

const DashboardApp = () => {
  //  Check login status
  const navigate = useNavigate();
  if (!hasTokenInLocalStorage() && !hasTokenInUrl()) {
    navigate("/login", { replace: true });
  }

  const [connectedUserData, setConnectedUserData] = useState([]);
  const [quarterMonth, setQuarterMonth] = useState(months);
  var [tsesGlobalData, setTsesGlobalData] = useState([]);
  const [globalOKRData, setGlobalOKRData] = useState({ nps: 0 });
  const [tsesGlobalCards, setTsesGlobalCards] = useState({
    cards: [],
    cdcTarget: "8",
  });

  useEffect(() => {
    fetch(`${locationURLs[process.env.REACT_APP_URL] || ""}/getGlobalOKR`, {
      method: "post",
      body: JSON.stringify({ name: "nps" }),
      headers: { "Content-type": "application/json" },
    }).then((response) =>
      response.json().then((json) => setGlobalOKRData({ nps: json.data }))
    );
  }, []);

  const handleQuarterChange = (event, newQuarter) => {
    months = newQuarter;
    setQuarterMonth(newQuarter);
    //console.log(months);
  };

  // console.log('dashboard app');
  // console.log(tseMembers);

  useEffect(() => {
    const loadTable = async () => {
      tsesData = [];
      let quarterData = await getQuarterData();
      // console.log(quarterData);
      let tseJson = await getTSEJson(quarterData, tseMembers);
      // console.log(tseJson);
      // console.log('here');
      tsesData = await calculateTSEQuarterResults(tsesData);
      // console.log(tsesData);
      let connectedTseData = await getConnectedTse(tsesData);
      // console.log('connecter', connectedTseData);

      //specific case of none data for the quarter
      if (connectedTseData.length == 0) {
        connectedTseData = nullDataTemplate;
      }

      let connectedUserData = connectedTseData[0];
      sessionStorage.setItem("name", connectedUserData.fullName);

      //add activity rate
      if (
        sessionStorage.getItem("name") != null &&
        sessionStorage.getItem("name") != "No Data"
      ) {
        fullName = connectedUserData.fullName;
        let activityData = await getActivityData();
        let activityRate = calculateActivityRate(activityData);
        connectedUserData.activityRate = activityRate;
        const globalCards = [];
        for (const tse of tsesData) {
          if (tse.id) {
            if (!tse?.cards) continue;
            tse.cards.forEach((card) => {
              globalCards.push(card);
            });
          }
        }
        setTsesGlobalCards({
          cards: globalCards,
          cdcTarget: 8,
        });

        //enable here to have tqs calculated by day off
        //var tqsWithDayOff = calculateTQSwithDayOff(activityData, connectedUserData);
        //connectedUserData.tqs = tqsWithDayOff;
      }

      //set specific data on dashboard
      setTsesGlobalData(tsesData);
      setConnectedUserData(connectedUserData);

      console.log("your raw data", connectedUserData);
      console.log("current global raw data", {
        totalDifficultyScore: tsesData.difficultyScoreTally,
        totalWorkRate: tsesData.workRateTally,
        cdc: tsesData.averageCdc,
        cdcTally: tsesData.cdcTally,
      });
    };

    loadTable();
  }, [quarterMonth]);

  function ShowRespectedEstimation() {
    if (isManager) {
      return (
        <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
          <AppRespectedEstimation data={connectedUserData} />
        </Grid>
      );
    } else {
      return "";
    }
  }

  return (
    <Page title="Okr & Stats">
      <Container maxWidth="xl">
        <Box sx={{ mb: 5 }}>
          <Typography variant="h3">{connectedUserData.fullName}</Typography>
        </Box>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h5">Individual OKRs</Typography>
        </Box>
        <Grid container spacing={3}>
          <Container maxWidth="xl">
            <Box component="div" sx={{ display: "flex", mb: 5 }}>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppRespectedEstimation data={connectedUserData} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppActivityRate data={connectedUserData} />
              </Grid>
              {!isManager && !tseExpertList.includes(connectedUserData.id) && (
                <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                  <AppTrelloQualityScore data={connectedUserData} />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppCDC data={connectedUserData} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppEstimationPercentage data={connectedUserData} />
              </Grid>
            </Box>
          </Container>
        </Grid>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h5">OKRs Globaux</Typography>
        </Box>
        <Grid container spacing={3}>
          <Container maxWidth="xl">
            <Box component="div" sx={{ display: "flex", mb: 5 }}>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppCDC data={tsesGlobalCards} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppRespectedEstimation data={tsesGlobalCards} />
              </Grid>
            </Box>
          </Container>
        </Grid>
      </Container>
      <div>Calculations:</div>
      <div>
        Activity rate: total hours in quarter / (total hours booked in Broggl
        (but NOT_BILLABLE) - number of hours of days off)
      </div>
      <div>
        TQS: your total score / your expected share of the global total score.
        Points are given for each TSE on the card.
      </div>
      <div>
        CDC: number of days between the time the card is taken in charge by a
        TSE to the date the card is validated (top 5 and bottom 5% of the cards
        excluded)
      </div>
      <div>Global CDC: average of everyone's CDC</div>
      <div>NPS: sum of the promoters - sum of detractors</div>
    </Page>
  );
};

export default DashboardApp;
